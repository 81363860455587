:root {
    --primary-lighten-7: #fff3df !important; // 60%
    --primary-lighten-6: #feeac6 !important;
    --primary-lighten-5: #fee1ad !important;
    --primary-lighten-4: #fdd894 !important;
    --primary-lighten-3: #fdcf7a !important;
    --primary-lighten-2: #fdc661 !important;
    --primary-lighten-1: #fcbd48 !important;
    --primary: #fcb42f !important;
    --primary-darken-4: #fcab16 !important;
    --primary-darken-3: #f4a004 !important;
    --primary-darken-2: #db8f03 !important;
    --primary-darken-1: #c27f03 !important;
}